import './Header.css'

function Header() {
    return (
        <div className="header">
            <a href="#about">About</a>
            <a href="#skills">Skills and Experience</a>
        </div>
    )
}

export default Header;
