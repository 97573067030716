import Header from "./Header";

import './App.css';

import mountainBg from './img/PXL_20211212_000342885.jpg';
import tomsThumb from './img/PXL_20211023_180135355.jpg';

import githubLogo from './img/GitHub-Mark-Light-120px-plus.png';
import gitlabLogo from './img/gitlab-icon-rgb.png';
import linkedinLogo from './img/LI-In-Bug.png';

function App() {
    return (
        <div>
            <Header/>
            <div id="about" className="fullScreenContainer">
                <img src={mountainBg} alt="Mountains" className="fullScreenImage"/>
                <div className="fullScreenOverlay">
                    <h1>Jude Brauer</h1>
                    <p>
                        Software Engineer at State Farm
                    </p>
                    <div className="row">
                        <a href="https://github.com/jnbrauer" target="_blank" rel="noreferrer">
                            <img src={githubLogo} alt="Github logo" className="socialIcon"/>
                        </a>
                        <a href="https://gitlab.com/jnbrauer" target="_blank" rel="noreferrer">
                            <img src={gitlabLogo} alt="GitLab logo" className="socialIcon"/>
                        </a>
                        <a href="https://www.linkedin.com/in/jude-brauer-68a923193/" target="_blank" rel="noreferrer">
                            <img src={linkedinLogo} alt="LinkedIn logo" className="socialIcon"/>
                        </a>
                    </div>
                </div>
            </div>

            <div id="skills" className="fullScreenContainer">
                <img src={tomsThumb} alt="Tom's Thumb" className="fullScreenImage"/>
                <div className="fullScreenOverlay">
                    <h1>Skills</h1>
                    <div id="skillsList" className="row">
                        <p>Python</p>
                        <p>Java</p>
                        <p>AWS</p>
                        <p>Spring Boot</p>
                        <p>Terraform</p>
                        <p>JavaScript</p>
                        <p>React.js</p>
                    </div>
                    <h1>Experience</h1>
                    <ul>
                        <li>Software Engineer @ State Farm (2023-Present)</li>
                        <li>Software Engineer @ Padma AgRobotics (2022-2024)</li>
                        <li>Technology Intern @ State Farm (2021-2022)</li>
                        <li>Software Developer @ AdvanceWare (2020-2022)</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default App;
